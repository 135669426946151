<script lang="ts">
	import { Turnstile } from 'svelte-turnstile';

	export let reset: () => void | undefined;
	export let turnstile = null;
	async function challengePassed(event) {
		if (event.detail.token) {
			turnstile = event.detail.token;
			// console.log(turnstile);
		}
	}
</script>

<Turnstile
	bind:reset
	siteKey="0x4AAAAAAAD9OnExkg5IyF7E"
	retryInterval={1000}
	theme="light"
	appearance="interaction-only"
	tabIndex={1}
	formsField="cfTurnstileResponse"
	on:turnstile-callback={challengePassed}
/>
