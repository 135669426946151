import validator from 'validator';
import { z } from 'zod';

const lead = z.object({
	id: z.string().max(36).or(z.literal('')).nullish(),
	firstName: z.string().max(255).nullish(),
	lastName: z.string().min(1, { message: 'Last Name is required.' }).max(255),
	phone: z
		.string()
		.max(40)
		.refine(validator.isMobilePhone, {
			message: 'Phone number appears to be invalid.',
		})
		.or(z.literal(''))
		.nullish(),
	postalCode: z
		.string()
		.trim()
		.max(20)
		.refine((zip) => validator.isPostalCode(zip, 'any'), {
			message: 'Postal code appears to be invalid.',
		})
		.or(z.literal(''))
		.nullish(),
	country: z.string().max(255).default('United States').nullish(),
	email: z
		.string()
		.max(80)
		.email({ message: 'Valid email address is required.' }),
	windowCount: z.number().int().positive().nullish(),
	url: z.string().max(10000).or(z.literal('')).nullish(),
	notes: z.string().max(2000).nullish(),
	formName: z.string().max(255).or(z.literal('')).nullish(),
	gclid: z.string().max(255).or(z.literal('')).nullish(),
	gbraid: z.string().max(255).or(z.literal('')).nullish(),
	wbraid: z.string().max(255).or(z.literal('')).nullish(),
	rdtCid: z.string().max(255).or(z.literal('')).nullish(),
	rdtUuid: z.string().max(255).or(z.literal('')).nullish(),
	qclid: z.string().max(255).or(z.literal('')).nullish(),
	fbp: z.string().max(255).or(z.literal('')).nullish(),
	fbclid: z.string().max(255).or(z.literal('')).nullish(),
	piwikproVisitorId: z.string().max(255).or(z.literal('')).nullish(),
	isResidential: z.boolean().nullish(),
	isCommercial: z.boolean().nullish(),
	smsOptOut: z.boolean().nullish(),
	cfTurnstileResponse: z.string().max(2048).or(z.literal('')).nullish(),
	supportOrderNumber: z.string().max(255).or(z.literal('')).nullish(),
	supportIssue: z.string().max(255).or(z.literal('')).nullish(),
	company: z.string().max(255).or(z.literal('')).nullish(),
	focusSound: z.boolean().nullish(),
	supportNotes: z.string().max(2000).or(z.literal('')).nullish(),
	stateIncorporated: z.string().max(80).or(z.literal('')).nullish(),
	website: z.string().max(255).or(z.literal('')).nullish(),
	isContractor: z.boolean().nullish(),
	contractorTermsAccepted: z.boolean().nullish(),
	city: z.string().max(40).or(z.literal('')).nullish(),
	street: z.string().max(255).or(z.literal('')).nullish(),
	state: z.string().max(80).or(z.literal('')).nullish(),
	userAgent: z.string().max(255).or(z.literal('')).nullish(),
	inquiryType: z.string().max(255).or(z.literal('')).nullish(),
	images: z
		.array(
			z
				.instanceof(File)
				.refine((f) => f.size < 25000000, 'File must be less than 25 MB.')
				.optional(),
		)
		.optional(),
	type: z.string().max(255).or(z.literal('')).nullish(),
	salesforce: z.boolean().nullish(),
});

export default lead;
